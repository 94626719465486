import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          image={`url(${mobileHero}) top / cover no-repeat`}
          column
          alignCenter
        >
          <Header />
          <CFView textCenter w="100%">
            <CFView>
              <CFImage
                w="100%"
                src={mobileHeroText}
                alt="Wasabi Sushi  Bar hero text"
                zIndex={98}
              />
            </CFView>
            <CFView pulsate mt="20px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignStart
        >
          <Header />
          <CFView column justifyCenter alignStart ml="135px" mt="70px">
            <CFImage
              w="490px"
              src={heroText}
              alt="Wasabi Sushi  Bar hero text"
              zIndex={98}
            />
            <CFView pulsate mt="50px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
